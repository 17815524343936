<template>
  <div id="open-ai-wrap" class="open-ai-wrap" @dragend="enddrag">
    <div
      id="open-ai-button"
      class="open-ai-button"
      draggable="true"
      @click="showDialogFn"
    >
      <i class="iconfont icon-a-zhishikuai1" style="font-size: 26px"></i>
    </div>
    <div v-show="showDialog" class="open-ai-mask"></div>
    <transition name="el-zoom-in-center">
      <div
        v-show="showDialog"
        id="open-ai-dialog"
        class="open-ai-dialog"
        :class="{
          'started-wrap': !started,
        }"
      >
        <div class="close-open-ai-dialog" @click="showDialog = false">
          <i class="el-icon-close"></i>
        </div>
        <div v-if="started" ref="dialogRecord" class="open-ai-dialog-record">
          <div
            v-for="(item, index) in recordList"
            :key="index"
            :style="{
              'text-align': item.isRobot ? 'left' : 'right',
            }"
          >
            <span
              :class="{
                'open-ai-dialog-record-item-user': !item.isRobot,
                'open-ai-dialog-record-item-robot': item.isRobot,
              }"
              v-html="item.message"
            >
            </span>

            <label
              v-if="
                robotTyping && item.isRobot && index === recordList.length - 1
              "
              class="dot-elastic"
            ></label>
          </div>
        </div>
        <div
          v-if="
            started &&
            !robotTyping &&
            recordList[recordList.length - 1] &&
            recordList[recordList.length - 1].isRobot
          "
          class="open-ai-dialog-tip"
        >
          <div
            v-for="(item, index) in tipList"
            :key="index"
            @click="pushTip(item)"
          >
            <span> {{ item }} → </span>
          </div>
        </div>
        <div
          class="open-ai-dialog-edit"
          :class="{
            'started-open-ai-dialog-edit': !started,
          }"
        >
          <div v-if="started" class="open-ai-dialog-edit-header">
            <span>{{
              currentRequirement ? currentRequirement.name : "自定义对话"
            }}</span>
            <i
              v-if="!robotTyping"
              class="el-icon-close"
              style="cursor: pointer"
              @click="clearCurrentRequirement"
            ></i>
          </div>
          <div
            class="open-ai-dialog-edit-main"
            :class="{
              'started-open-ai-dialog-main': started,
            }"
          >
            <div
              class="open-ai-dialog-edit-editable placeholder"
              ref="aiContent"
              contenteditable="true"
              data-content="请输入@或/调出命令"
              @keydown.enter.prevent="sendMessage"
              @input="handleInput"
            ></div>
            <div class="open-ai-dialog-edit-tool">
              <i
                class="iconfont icon-fasong"
                style="
                  color: #fff;
                  font-size: 20px;
                  width: 26px;
                  height: 26px;
                  border-radius: 4px;
                  padding: 2px;
                  background: rgba(0, 76, 170, 1);
                "
                :style="{
                  background: robotTyping ? '#C8D8E6' : 'rgba(0, 76, 170, 1)',
                }"
                @click="sendMessage"
              >
              </i>
            </div>
            <transition name="el-zoom-in-top">
              <div v-if="showRequirementsList" class="requirementsList-fixed">
                <div
                  v-for="(item, index) in requirementsList"
                  :key="index"
                  @click="choseRequirement(item)"
                >
                  <span>{{ item.name }}</span>
                  <span>{{ item.description }}</span>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getRequirements } from "@/network/newAI";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { mapGetters } from "vuex";
import MarkdownIt from "markdown-it";
import { ulid } from "ulid";
export default {
  data() {
    return {
      showDialog: false,
      recordList: [],
      tipList: [
        "请进一步完善这个回答",
        "我对这个回答很满意，请帮我在MappingSpace中创建一个文件",
      ],
      requirementsList: [],
      currentRequirement: null,
      robotTyping: false,
      started: false,
      showRequirementsList: false,
      originData: "",
      sessionId: ulid(),
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {
    this.getRequirements();
  },
  methods: {
    getResponse(message) {
      const md = new MarkdownIt({ html: true, breaks: true, xhtmlOut: true });
      const self = this;
      const obj = {
        isRobot: true,
        message: "",
      };
      self.robotTyping = true;
      self.recordList.push(obj);
      self.originData = "";
      self.eventSource = fetchEventSource(
        `${self.wsurl}${
          self.wsurl ? "" : "/prod-api"
        }/alm/${this.get_pid()}/response?sessionId=${
          self.sessionId
        }&prompt=${message}${
          self.currentRequirement
            ? `&action_type=${self.currentRequirement.type}`
            : ``
        }`,
        {
          method: "get",
          headers: {
            "Content-Type": "text/html",
            Authorization: self.token,
            "Accept-Language": self.languageType,
          },
          openWhenHidden: true,
          onmessage(event) {
            self.originData += JSON.parse(event.data).data;
            obj.message = md.render(self.originData);
            self.scrollTobottom();
          },
          onerror(err) {
            throw err;
          },
          onclose() {
            self.robotTyping = false;
          },
        }
      );
    },
    getRequirements() {
      getRequirements().then((res) => {
        this.requirementsList = res;
      });
    },
    enddrag(a, b) {
      document.getElementById("open-ai-button").style.top = a.pageY - 25 + "px";
      document.getElementById("open-ai-button").style.left =
        a.pageX - 25 + "px";
      document.getElementById("open-ai-button").style.bottom =
        a.pageY + 25 + "px";
      document.getElementById("open-ai-button").style.right =
        a.pageX + 25 + "px";
    },
    showDialogFn() {
      this.showDialog = !this.showDialog;
    },
    getContent() {
      // 获取 contenteditable 元素的 HTML 内容
      const htmlContent = this.$refs.aiContent.innerHTML;

      // 获取纯文本内容
      const textContent =
        this.$refs.aiContent.innerText || this.$refs.aiContent.textContent;

      console.log("HTML Content:", htmlContent);
      console.log("Text Content:", textContent);

      return textContent; // 或者返回 htmlContent，根据需求选择
    },

    clearContent() {
      // 清空 contenteditable 元素的内容
      this.$refs.aiContent.innerHTML = "";
      // 或者使用 innerText
      this.$refs.aiContent.innerText = "";
    },

    pushTip(message) {
      this.recordList.push({
        isRobot: false,
        message,
      });
      this.scrollTobottom();
      this.getResponse(message);
    },
    scrollTobottom() {
      this.$nextTick(() => {
        this.$refs.dialogRecord
          ? (this.$refs.dialogRecord.scrollTop =
              this.$refs.dialogRecord.scrollHeight)
          : "";
      });
    },
    sendMessage() {
      const message = this.getContent();
      if (message.trim() && !this.robotTyping) {
        this.started = true;
        this.showRequirementsList = false;
        // 确保消息不为空
        this.recordList.push({
          isRobot: false,
          message,
        });
        this.clearContent();
        this.scrollTobottom();
        this.getResponse(message);
      }
    },
    // 选择需求
    choseRequirement(item) {
      this.currentRequirement = item;
      this.started = true;
      this.showRequirementsList = false;
      const contentEditable = this.$refs.aiContent;
      contentEditable.classList.remove("placeholder");
      contentEditable.focus();
    },
    // 清空选择
    clearCurrentRequirement() {
      this.clearContent();

      this.started = false;
      this.currentRequirement = null;
      this.recordList = [];
      const contentEditable = this.$refs.aiContent;
      contentEditable.classList.add("placeholder");
    },
    // 输入事件
    handleInput() {
      const contentEditable = this.$refs.aiContent;
      const textContent = contentEditable.innerText.trim();

      if (textContent.length === 1 && !this.started) {
        const firstChar = textContent[0];
        if (firstChar === "@" || firstChar === "/") {
          this.showRequirementsList = true;
          this.clearContent();
          return;
        }
      }
      if (!this.started) {
        if (contentEditable.innerText.trim() === "") {
          contentEditable.classList.add("placeholder");
        } else {
          contentEditable.classList.remove("placeholder");
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.open-ai-wrap {
  position: absolute;
  right: 25px;
  bottom: 65px;
  z-index: 1999;
  cursor: pointer;
  .open-ai-button {
    display: flex;
    background: linear-gradient(
      90deg,
      rgba(100, 132, 250, 1) 0%,
      rgba(102, 232, 255, 1) 100%
    );
    align-items: center;
    color: white;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 100;
  }
  .open-ai-mask {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .started-wrap {
    background-image: none !important;
    top: 30% !important;
    padding-top: 0 !important;
    background: linear-gradient(
      180deg,
      rgba(68, 149, 248, 1) 0%,
      rgba(198, 223, 253, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    ) !important;
    .open-ai-dialog-edit-main {
      background-color: transparent !important;
    }
    .open-ai-dialog-edit-editable {
      padding: 20px !important;
    }
  }
  .open-ai-dialog {
    position: fixed;
    width: 1000px;
    min-height: 124px;
    z-index: 100;
    background: linear-gradient(
      180deg,
      rgba(68, 149, 248, 1) 0%,
      rgba(198, 223, 253, 1) 30%,
      rgba(255, 255, 255, 1) 70%
    );
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 移动元素到正确的位置 */
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    transition: all 0.5s ease;
    .close-open-ai-dialog {
      cursor: pointer;
      text-align: right;
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 16px;
      z-index: 99;
    }
    .open-ai-dialog-record {
      min-height: 300px;
      max-height: 500px;
      overflow-y: scroll;
      scrollbar-width: thin; /* Firefox */
      scrollbar-color: transparent transparent;
      padding-top: 40px;
      padding-right: 20px;
      padding-left: 20px;
      & > div {
        margin-bottom: 30px;
        font-size: 14px;
        width: 100%;
        height: fit-content;
        position: relative;
        & > span {
          display: inline-block;
          max-width: 70%;
        }
        .dot-elastic {
          display: inline-block;
          position: absolute;
          bottom: -20px;
          left: 40px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #2a82e4;
          color: #2a82e4;
          animation: dotElastic 1s infinite linear;
        }

        .dot-elastic::before,
        .dot-elastic::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
        }

        .dot-elastic::before {
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #2a82e4;
          color: #2a82e4;
          animation: dotElasticBefore 1s infinite linear;
        }

        .dot-elastic::after {
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #2a82e4;
          color: #2a82e4;
          animation: dotElasticAfter 1s infinite linear;
        }
        @keyframes dotElasticBefore {
          0% {
            transform: scale(1, 1);
          }
          25% {
            transform: scale(1, 1.5);
          }
          50% {
            transform: scale(1, 0.67);
          }
          75% {
            transform: scale(1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }

        @keyframes dotElastic {
          0% {
            transform: scale(1, 1);
          }
          25% {
            transform: scale(1, 1);
          }
          50% {
            transform: scale(1, 1.5);
          }
          75% {
            transform: scale(1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }

        @keyframes dotElasticAfter {
          0% {
            transform: scale(1, 1);
          }
          25% {
            transform: scale(1, 1);
          }
          50% {
            transform: scale(1, 0.67);
          }
          75% {
            transform: scale(1, 1.5);
          }
          100% {
            transform: scale(1, 1);
          }
        }
      }
      &-item-user {
        padding: 6px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 4px;
      }
      &-item-robot {
        padding: 6px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 4px;
      }
    }
    .open-ai-dialog-tip {
      text-align: left;
      padding-left: 20px;
      & > div {
        margin: 10px 0;
        & > span {
          display: inline-block;
          border-radius: 4px;
          padding: 4px;
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
    }
    .open-ai-dialog-record::-webkit-scrollbar {
      width: 0; /* Chrome, Safari and Opera */
    }
    .started-open-ai-dialog-edit {
      background: linear-gradient(
        180deg,
        rgba(68, 149, 248, 1) 0%,
        rgba(198, 223, 253, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );
      border-radius: 20px 20px;
    }
    .open-ai-dialog-edit {
      min-height: 60px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0);
      bottom: 0;
      &-header {
        height: 40px;
        width: 100%;
        border-radius: 20px 20px 0 0;
        background: linear-gradient(
          180deg,
          rgba(191, 220, 255, 1) 0%,
          rgba(232, 242, 255, 1) 50%,
          rgba(255, 255, 255, 1) 100%
        );
        position: relative;
        text-align: left;
        line-height: 40px;
        padding-left: 10px;
        span {
          font-size: 16px;
          font-weight: 400;
          color: rgba(56, 56, 56, 1);
        }
        i {
          cursor: pointer;
          text-align: right;
          position: absolute;
          right: 20px;
          top: 10px;
          font-size: 16px;
        }
      }
      &-main {
        background: #fff;
        border-radius: 20px 20px 20px 20px;
        .requirementsList-fixed::-webkit-scrollbar {
          width: 0; /* Chrome, Safari and Opera */
        }
        .requirementsList-fixed {
          width: 100%;
          position: absolute;
          bottom: -220px;
          left: 0;
          padding: 20px 0;
          background-color: #fff;
          border-radius: 20px;
          max-height: 200px;
          height: 200px;
          overflow-y: scroll;
          scrollbar-width: thin; /* Firefox */
          scrollbar-color: transparent transparent;
          & > div {
            font-size: 14px;
            text-align: left;
            height: 30px;
            line-height: 30px;
            padding: 0 20px;
            cursor: pointer;
            &:hover {
              background-color: #eee;
            }
            & > span:first-of-type {
              color: #707070;
              margin-right: 20px;
            }
            & > span:last-of-type {
              color: #b6b7ba;
            }
          }
        }
      }
      .started-open-ai-dialog-main {
        border-radius: 0 0 20px 20px;
      }
      &-editable {
        color: #000;
        width: 100%;
        min-height: 100px;
        outline: none;
        width: 100%;
        flex: none;
        word-break: break-all;
        white-space: pre-wrap;
        text-align: left;
        font-size: 14px;
        padding: 0 20px 20px 20px;
        position: relative;
        border-radius: 20px;
      }
      .open-ai-dialog-edit-editable.placeholder::before {
        content: attr(data-content);
        color: #333;
        pointer-events: none;
        position: absolute;
        left: 20px;
        top: 20px;
      }
      &-tool {
        text-align: right;
        padding: 20px 20px 20px 0px;
        border-radius: 0 0 20px 20px;
      }
    }
  }
}
code {
  white-space: break-spaces;
}
</style>
