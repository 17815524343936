var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"open-ai-wrap",attrs:{"id":"open-ai-wrap"},on:{"dragend":_vm.enddrag}},[_c('div',{staticClass:"open-ai-button",attrs:{"id":"open-ai-button","draggable":"true"},on:{"click":_vm.showDialogFn}},[_c('i',{staticClass:"iconfont icon-a-zhishikuai1",staticStyle:{"font-size":"26px"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDialog),expression:"showDialog"}],staticClass:"open-ai-mask"}),_c('transition',{attrs:{"name":"el-zoom-in-center"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDialog),expression:"showDialog"}],staticClass:"open-ai-dialog",class:{
        'started-wrap': !_vm.started,
      },attrs:{"id":"open-ai-dialog"}},[_c('div',{staticClass:"close-open-ai-dialog",on:{"click":function($event){_vm.showDialog = false}}},[_c('i',{staticClass:"el-icon-close"})]),(_vm.started)?_c('div',{ref:"dialogRecord",staticClass:"open-ai-dialog-record"},_vm._l((_vm.recordList),function(item,index){return _c('div',{key:index,style:({
            'text-align': item.isRobot ? 'left' : 'right',
          })},[_c('span',{class:{
              'open-ai-dialog-record-item-user': !item.isRobot,
              'open-ai-dialog-record-item-robot': item.isRobot,
            },domProps:{"innerHTML":_vm._s(item.message)}}),(
              _vm.robotTyping && item.isRobot && index === _vm.recordList.length - 1
            )?_c('label',{staticClass:"dot-elastic"}):_vm._e()])}),0):_vm._e(),(
          _vm.started &&
          !_vm.robotTyping &&
          _vm.recordList[_vm.recordList.length - 1] &&
          _vm.recordList[_vm.recordList.length - 1].isRobot
        )?_c('div',{staticClass:"open-ai-dialog-tip"},_vm._l((_vm.tipList),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.pushTip(item)}}},[_c('span',[_vm._v(" "+_vm._s(item)+" → ")])])}),0):_vm._e(),_c('div',{staticClass:"open-ai-dialog-edit",class:{
          'started-open-ai-dialog-edit': !_vm.started,
        }},[(_vm.started)?_c('div',{staticClass:"open-ai-dialog-edit-header"},[_c('span',[_vm._v(_vm._s(_vm.currentRequirement ? _vm.currentRequirement.name : "自定义对话"))]),(!_vm.robotTyping)?_c('i',{staticClass:"el-icon-close",staticStyle:{"cursor":"pointer"},on:{"click":_vm.clearCurrentRequirement}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"open-ai-dialog-edit-main",class:{
            'started-open-ai-dialog-main': _vm.started,
          }},[_c('div',{ref:"aiContent",staticClass:"open-ai-dialog-edit-editable placeholder",attrs:{"contenteditable":"true","data-content":"请输入@或/调出命令"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.sendMessage.apply(null, arguments)},"input":_vm.handleInput}}),_c('div',{staticClass:"open-ai-dialog-edit-tool"},[_c('i',{staticClass:"iconfont icon-fasong",staticStyle:{"color":"#fff","font-size":"20px","width":"26px","height":"26px","border-radius":"4px","padding":"2px","background":"rgba(0, 76, 170, 1)"},style:({
                background: _vm.robotTyping ? '#C8D8E6' : 'rgba(0, 76, 170, 1)',
              }),on:{"click":_vm.sendMessage}})]),_c('transition',{attrs:{"name":"el-zoom-in-top"}},[(_vm.showRequirementsList)?_c('div',{staticClass:"requirementsList-fixed"},_vm._l((_vm.requirementsList),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.choseRequirement(item)}}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.description))])])}),0):_vm._e()])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }